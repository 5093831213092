import classNames from 'classnames';
import { FC } from 'react';

import { Accordion, Image, SvgIcon, Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';
import * as styles from './styles.module.css';

type IDocumentTypes = {
  title: string;
  description: string;
  hint: string;
  list: {
    label: string;
    children: string[];
  }[];
};

export const DocumentTypes: FC = (props) => {
  const { title, description, hint, list } = useTranslateData<IDocumentTypes>(
    'sections.documentTypes'
  ) as IDocumentTypes;

  return (
    <section {...props}>
      <div
        className={classNames(
          'container',
          'pt-[40px]',
          'xs:pt-[80px]',
          'sm:pt-[90px]',
          'lg:pt-[136px]',
          'pb-[36px]',
          'xs:pb-[64px]',
          'sm:pb-[56px]',
          'lg:pb-[101px]'
        )}
      >
        <Headline
          size={2}
          className={classNames(
            'mb-[16px]',
            'sm:mb-[21px]',
            'lg:mb-[30px]',
            'lg:max-w-[900px]',
          )}
        >
          <Trans>{title}</Trans>
        </Headline>
        <div
          className={classNames(
            'mb-[24px]',
            'xs:mb-[15px]',
            'sm:mb-[53px]',
            'lg:mb-[50px]',
            'xs:max-w-[380px]',
            'lg:max-w-[930px]',
            'sm:max-w-full'
          )}
        >
          <Trans>{description}</Trans>
        </div>
        <div
          className={classNames(
            'lg:flex',
            'mb-[58px]',
            'sm:mb-[44px]',
            'lg:mb-[34px]'
          )}
        >
          <div
            className={classNames(
              'hidden',
              'lg:block',
              'lg:max-w-[470px]',
              'xl:max-w-[676px]'
            )}
          >
            <div className={classNames('lg:ml-[-94px]', 'xl:ml-[-30px]')}>
              <Image src="document_type_example.jpg" alt="" />
            </div>
          </div>
          <div
            className={classNames(
              'flex-auto',
              'sm:relative',
              'sm:left-[16px]',
              'lg:left-[0px]',
              'xl:left-[17px]',
              'lg:mt-[80px]',
              'xl:mt-[63px]'
            )}
          >
            {list.map(({ label, children }, i) => (
              <Accordion
                key={label}
                title={<Trans>{label}</Trans>}
                className={classNames(
                  styles.accordion,
                  'sm:w-2/4',
                  'sm:overflow-hidden',
                  'px-[10px]'
                )}
                titleClassName={classNames(
                  'h-[45px]',
                  'sm:text-[20px]',
                  'lg:text-[24px]',
                  'sm:leading-[23px]',
                  'lg:leading-[28px]',
                  'tracking-[-0.2px]',
                  'sm:tracking-[-0.4px]'
                )}
                iconClassName={classNames('sm:hidden')}
                panelClassName={styles.panel}
                expanded={!i}
              >
                <ul
                  className={classNames(
                    'list-outside',
                    'list-square',
                    'space-y-[14px]',
                    'pt-[2px]',
                    'sm:pt-[4px]',
                    'lg:pt-[6px]',
                    'pb-[24px]',
                    'sm:pb-[17px]',
                    'lg:pb-[22px]',
                    'pl-[38px]',
                    'sm:pl-[22px]',
                    'text-orange-60',
                    'sm:text-[18px]',
                    'sm:leading-[23px]',
                    'sm:tracking-[-0.55px]'
                  )}
                >
                  {children.map((item) => (
                    <li key={item}>
                      <div className="text-graphite-90">
                        <Trans>{item}</Trans>
                      </div>
                    </li>
                  ))}
                </ul>
              </Accordion>
            ))}
          </div>
        </div>

        <div
          className={classNames(
            'text-orange-60',
            'max-w-[280px]',
            'lg:max-w-[300px]',
            'relative',
            'transform',
            'translate-x-[65px]',
            'xs:translate-x-[142px]',
            'sm:translate-x-[4px]',
            'lg:translate-x-[2px]',
            'sm:ml-[34%]',
            'lg:ml-[28%]',
            'xl:ml-[41%]',
            'text-[16px]',
            'leading-[21px]',
            'lg:text-[20px]',
            'lg:leading-[26px]',
            'tracking-[-0.1px]'
          )}
        >
          <div
            className={classNames(
              'text-graphite-60',
              'absolute',
              'right-full',
              'lg:right-auto',
              'lg:left-full',
              'bottom-full',
              'transform',
              'translate-x-[-8px]',
              'translate-y-[14px]',
              'lg:translate-y-[6px]',
              'lg:translate-x-[18px]',
              'lg:scale-x-[-2.1]',
              'lg:scale-y-[2.1]',
              'lg:rotate-[18deg]'
            )}
          >
            <SvgIcon name="rounded_arrow_up" />
          </div>
          <Trans>{hint}</Trans>
        </div>
      </div>
    </section>
  );
};
