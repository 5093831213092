import classNames from 'classnames';
import { FC } from 'react';

import { SvgIcon, Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';

export const PrinciplesOperation: FC = (props) => {
  const { title, list } = useTranslateData('sections.principlesOperation');

  return (
    <section {...props}>
      <div
        className={classNames(
          'container',
          'pt-[39px]',
          'sm:pt-[86px]',
          'lg:pt-[92px]',
          'pb-[70px]',
          'sm:pb-[108px]',
          'lg:pb-[206px]'
        )}
      >
        <Headline
          size={2}
          className={classNames(
            'text-center',
            'mb-[36px]',
            'sm:mb-[22px]',
            'lg:mb-[106px]'
          )}
        >
          <Trans>{title}</Trans>
        </Headline>
        <div
          className={classNames(
            'text-center',
            'max-w-[250px]',
            'sm:max-w-full',
            'mx-auto',
            'space-y-[45px]',
            'sm:space-y-[0px]',
            'sm:flex',
            'sm:flex-wrap',
            'sm:justify-center',
            'sm:text-[18px]',
            'sm:leading-[23px]',
            'sm:tracking-[-0.55px]',
            'lg:text-[20px]',
            'lg:leading-[26px]',
            'lg:tracking-[-0.5px]',
            'xl:justify-between'
          )}
        >
          {list.map(({ icon, text }) => (
            <div
              key={text}
              className={classNames(
                'sm:w-[50%]',
                'lg:w-[calc(100%/3)]',
                'xl:max-w-[320px]',
                'sm:px-[30px]',
                'lg:px-[20px]',
                'sm:box-border',
                'xl:box-content',
                'sm:pt-[54px]',
                'lg:pt-[0px]'
              )}
            >
              <div
                className={classNames(
                  'text-green-90',
                  'mb-[17px]',
                  'sm:mb-[20px]'
                )}
              >
                <SvgIcon
                  name={icon}
                  className={classNames(
                    'w-[45px]',
                    'h-[45px]',
                    'sm:w-[70px]',
                    'sm:h-[70px]',
                    'lg:w-[80px]',
                    'lg:h-[80px]',
                    'mx-auto'
                  )}
                />
              </div>
              <div>{text}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
