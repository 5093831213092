import classNames from 'classnames';
import { FC } from 'react';

import { Image, SvgIcon, Trans, Video } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';
import * as styles from './styles.module.css';

export const HowItWorksText: FC = (props) => {
  const { title, list } = useTranslateData('sections.howItWorksText');

  return (
    <section {...props}>
      <div className="bg-orange-35">
        <div
          className={classNames(
            'container',
            'pb-[56px]',
            'sm:pb-[282px]',
            'lg:pb-[460px]'
          )}
        >
          <Headline
            size={2}
            className={classNames(
              'text-center',
              'mb-[31px]',
              'xs:mb-[36px]',
              'sm:mb-[90px]',
              'lg:mb-[188px]'
            )}
          >
            <Trans>{title}</Trans>
          </Headline>
          <ul
            className={classNames(
              'xs:max-w-[294px]',
              'sm:max-w-[720px]',
              'lg:max-w-[1145px]',
              'mx-auto',
              'sm:text-[18px]',
              'lg:text-[24px]',
              'sm:leading-[23px]',
              'lg:leading-[32px]',
              'sm:tracking-[-0.55px]',
              'lg:tracking-[-0.5px]'
            )}
          >
            {list.map(({ label, icon, description, image, video }, index) => {
              const isOdd = index % 2 === 0;
              const arr = Array.isArray(label) ? label : [label];

              return (
                <li
                  key={index}
                  className={classNames('sm:relative', styles.item)}
                >
                  {index !== 0 && (
                    <div
                      className={classNames(
                        styles.line,
                        'hidden',
                        'sm:block',
                        isOdd && styles.line_odd
                      )}
                    />
                  )}

                  <div
                    className={classNames(
                      styles.content,
                      'sm:pr-[10px]',
                      isOdd
                        ? ['sm:ml-[37%]', 'lg:ml-[53%]', 'sm:max-w-[450px]', 'lg:max-w-[540px]']
                        : ['sm:max-w-[394px]'],
                      'relative',
                      'ml-[-6px]',
                      'xs:ml-[0px]',
                      'sm:z-[1]'
                    )}
                  >
                    <div
                      className={classNames(
                        'flex',
                        'space-x-[3px]',
                        'sm:ml-[56px]',
                        'items-start'
                      )}
                    >
                      <div
                        className={classNames(
                          'text-orange-60',
                          'sm:transform',
                          'sm:scale-[2]',
                          'sm:mr-[24px]',
                          'sm:mt-[14px]',
                          'lg:scale-[2.8]',
                          'lg:mr-[36px]',
                          'lg:mt-[30px]',
                        )}
                      >
                        <SvgIcon name={icon} />
                      </div>
                      <div>
                        <div
                          className={classNames(
                            'space-y-[8px]',
                            'sm:space-y-[14px]',
                            'lg:space-y-[19px]'
                          )}
                        >
                          {arr.map((item) => (
                            <div key={item}>
                              <Trans>{item}</Trans>
                            </div>
                          ))}
                        </div>

                        {description?.value && (
                          <div
                            className={classNames(
                              'mt-[10px]',
                              'lg:mt-[20px]',
                              'bg-orange-45',
                              'px-[8px]',
                              'sm:px-[14px]',
                              'pt-[13px]',
                              'sm:pt-[16px]',
                              'lg:pt-[20px]',
                              'pb-[12px]',
                              'lg:pb-[20px]',
                              'rounded-[8px]',
                              'flex',
                              'sm:mt-[16px]',
                              'text-[13px]',
                              'sm:text-[14px]',
                              'lg:text-[18px]',
                              'leading-[16px]',
                              'sm:leading-[18px]',
                              'lg:leading-[23px]',
                              'space-x-[6px]',
                              'sm:space-x-[8px]',
                              'lg:space-x-[3px]',
                              'tracking-[-0.1px]',
                              'sm:tracking-[-0.54px]',
                              'lg:tracking-[-0.55px]',
                              'lg:mr-[-12px]'
                            )}
                          >
                            {description?.icon && (
                              <div
                                className={classNames(
                                  'text-orange-60',
                                  'relative',
                                  'top-[3px]',
                                  'sm:top-[1px]',
                                  'lg:top-[-5px]',
                                  'w-[24px]',
                                  'sm:w-[40px]',
                                  'lg:w-[54px]',
                                  'flex-shrink-0',
                                  'transform',
                                  'scale-[1.2]',
                                  'lg:scale-[1]'
                                )}
                              >
                                <SvgIcon
                                  className={classNames(
                                    'w-[24px]',
                                    'h-[24px]',
                                    'sm:w-[40px]',
                                    'sm:h-[40px]',
                                    'lg:w-[54px]',
                                    'lg:h-[54px]'
                                  )}
                                  name={description.icon}
                                />
                              </div>
                            )}
                            <div>
                              <Trans>{description?.value}</Trans>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {image && (
                    <div
                      className={classNames(
                        styles.image,
                        'hidden',
                        'sm:block',
                        'sm:absolute'
                      )}
                    >
                      <Image src={image} />
                    </div>
                  )}
                  {video && (
                    <div
                      className={classNames(
                        styles.video,
                        'absolute',
                        'hidden',
                        'sm:block'
                      )}
                    >
                      <Video
                        name={video}
                        autoPlay="autoplay"
                        muted="muted"
                        loop="loop"
                        playsInline="playsinline"
                        //onGetVideoBgColor={handleGetVideoBgColor}
                      />
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};
