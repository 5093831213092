import classNames from 'classnames';
import { FC } from 'react';

import { SvgIcon, Trans } from '../../../components';

interface IItemProps {
  className?: string;
  label: {
    value: string;
    placement?: 'bottom' | 'center';
    align?: 'center';
  };
  icon?: {
    name?: string;
    largeName?: string;
    fill?: boolean
    position?: 'absolute';
    placement?: ['top' | 'bottom', 'right' | 'left'];
  };
  size?: 'm' | 'l' | 'xl';
  variant?: 'primary' | 'secondary' | 'invert';
}

const Item: FC<IItemProps> = ({ label, icon }) => {
  return (
    <div className={classNames(
      'flex',
      'items-start',
      'sm:items-center',
      'h-auto',
      'sm:h-[70px]',
      'lg:h-[96px]',
      'pb-[40px]',
      'sm:pb-[0]',
      'sm:mb-[50px]'
      )}>
      <div className={classNames('flex-initial', 'lg:w-[100px]', 'sm:w-[70px]', 'w-[55px]')}>
        <SvgIcon
          name={icon?.name || ''}
          fill={icon?.fill || false}
          className={classNames('text-orange-60', 'sm:w-[60px]', 'w-[45px]', 'h-auto')}
        />
      </div>
      <div className={classNames(
        'flex-1',
        'text-[16px]',
        'sm:text-[18px]',
        'lg:text-[24px]',
        'leading-[20px]',
        'sm:leading-[23px]',
        'lg:leading-[32px]')}>
        <Trans>{label?.value}</Trans>
      </div>
    </div>
  );
};

export { Item };
export type { IItemProps };
