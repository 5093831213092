import classNames from 'classnames';
import { FC } from 'react';

import { Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';
import { IItemProps, Item } from './Item';
import * as styles from './styles.module.css';

type IWhatWeDo = {
  title: string;
  list: Omit<IItemProps, 'className'>[];
};

export const WhatWeDo: FC = (props) => {
  const { title, list } = useTranslateData<IWhatWeDo>(
    'sections.whatWeDo'
  ) as IWhatWeDo;

  return (
    <section {...props}>
      <div className={classNames('bg-orange-35', 'transition-colors')}>
        <div
          className={classNames(
            'pt-[81px]',
            'xs:pt-[149px]',
            'lg:pt-[380px]'
          )}
        >
          <div className="container">
            <Headline
              size={2}
              className={classNames(
                'text-center',
                'mx-auto',
                'mb-[40px]',
                'sm:mb-[57px]',
                'lg:mb-[84px]',
                'sm:max-w-[700px]',
                'lg:max-w-[1600px]',
                styles.head
              )}
            >
              <Trans>{title}</Trans>
            </Headline>
          </div>
          <div className={classNames(
            'max-w-[270px]',
            'sm:max-w-[718px]',
            'lg:max-w-[1024px]',
            'mx-auto',
            'sm:columns-2')}>
            {list.map((props, index) => {
              return (
                <Item key={index} {...props} />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
