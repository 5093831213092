import classNames from 'classnames';
import { FC, useRef, useState } from 'react';

import { Trans, Video } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';
import * as styles from './styles.module.css';

import basisDocsPreview from '../../assets/images/basisDocs-preview.jpg';

export const HowItWorksVideo: FC = (props) => {
  const { id, title } = useTranslateData('sections.howItWorksVideo');
  const [videoStarted, startVideo] = useState(false);
  const [duration, setDuration] = useState(null);
  const videoRef = useRef();

  const onPreviewClick = () => {
    startVideo(true);
    videoRef.current.play();
  };

  const handleLoadMetadata = (e) => {
    setDuration(Math.floor(e.target.duration));
  };

  return (
    <section id={id} {...props}>
      <div className={classNames('bg-orange-35', 'transition-colors')}>
        <div
          className={classNames(
            'container',
            'pt-[38px]',
            'sm:pt-[66px]',
            'lg:pt-[150px]',
            'pb-[41px]',
            'sm:pb-[72px]',
            'lg:pb-[180px]'
          )}
        >
          <Headline
            size={2}
            className={classNames(
              'text-center',
              'mb-[50px]'
            )}
          >
            <Trans>{title}</Trans>
          </Headline>
          <div className={classNames('aspect-w-16', 'aspect-h-9', 'relative')}>
            <Video
              name="basisDocs"
              className={classNames('rounded-[4px]', 'lg:rounded-[15px]')}
              poster={basisDocsPreview}
              autoPlay={false}
              playsInline
              preload="auto"
              controls
              ref={videoRef}
              onLoadMetadata={handleLoadMetadata}
            />
            <div
              className={classNames(
                'absolute',
                'inset-0',
                videoStarted ? 'invisible' : 'visible'
              )}
            >
              <img
                src={basisDocsPreview}
                className={classNames('rounded-[4px]', 'lg:rounded-[15px]')}
              />
              {!!duration && (
                <span
                  className={classNames(
                    'absolute',
                    'py-[6px]',
                    'px-[7px]',
                    'sm:py-[8px]',
                    'sm:px-[12px]',
                    'lg:py-[10px]',
                    'lg:px-[14px]',
                    'text-white',
                    'rounded-[4px]',
                    'sm:rounded-[8px]',
                    'bg-graphite-85',
                    'bg-opacity-50',
                    'top-[12px]',
                    'sm:top-[20px]',
                    'right-[10px]',
                    'sm:right-[20px]'
                  )}
                >
                  <Headline size={6} className={'font-medium'}>
                    {Math.floor(duration / 60)}:{duration % 60}
                  </Headline>
                </span>
              )}
              <span className={styles.playButton} onClick={onPreviewClick}>
                <span />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
