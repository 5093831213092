import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';

import {
  SendRequest,
  SvgIcon,
  Trans,
} from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';
import * as styles from './styles.module.css';

export const ProvideRecognition: FC = (props) => {
  const { success } = useTranslateData('components.requestPanel');
  const { title, description, list } = useTranslateData(
    'sections.provideRecognition'
  );
  const [isSuccess, setSuccess] = useState(false);

  const handleSuccess = useCallback(() => {
    setSuccess(true);
  }, []);

  return (
    <section {...props}>
      <div
        className={classNames(
          'bg-orange-60',
          'xl:bg-opacity-0',
          'text-white',
          'pt-[30px]',
          'sm:pt-[50px]',
          'lg:pt-[40px]',
          'pb-[38px]',
          'xs:pb-[51px]',
          'sm:pb-[61px]',
          styles.section
        )}
      >
        <div className={classNames('container')}>
          <div
            className={classNames(
              'xl:bg-orange-60',
              'xl:rounded-[15px]',
              'xl:pt-[80px]',
              'xl:pl-[96px]',
              'xl:pr-[105px]',
              'xl:pb-[62px]',
              styles.container
            )}
          >
            <Headline
              size={2}
              className={classNames(
                'mb-[14px]',
                'xs:mb-[15px]',
                'lg:text-[50px]',
                'lg:leading-[60px]',
                'lg:max-w-[1000px]',
                'lg:tracking-[-1.5px]'
              )}
            >
              <Trans>{title}</Trans>
            </Headline>
            <div>
              <Trans>{description}</Trans>
            </div>
            <div
              className={classNames(
                'mt-[30px]',
                'xs:mt-[29px]',
                'lg:mt-[35px]',
                'mb-[36px]',
                'xs:mb-[44px]',
                'sm:mb-[47px]'
              )}
            >
              {isSuccess ?
                <div className={classNames('text-center')}>
                  <div
                    className={classNames(
                      'flex',
                      'justify-center',
                      'text-orange-60',
                    )}
                  >
                    <SvgIcon
                      name="circle_done"
                      className={classNames(
                        'h-[36px]',
                        'w-[36px]',
                        'lg:w-[55px]',
                        'lg:h-[55px]'
                      )}
                    />
                  </div>
                  <div
                    className={classNames(
                      'font-bold',
                      'text-[23px]',
                      'leading-[28px]',
                      'tracking-[-0.6px]',
                      'lg:text-[40px]',
                      'lg:leading-[49px]',
                      'lg:tracking-[-1.3px]',
                      'mb-[8px]'
                    )}
                  >
                    <Trans>{success?.title}</Trans>
                  </div>
                </div> :
                <SendRequest variant="dark" onSuccess={handleSuccess} />
              }
            </div>
            <ul
              className={classNames(
                'text-[14px]',
                'leading-[18px]',
                'text-orange-90',
                'space-y-[15px]',
                'xs:space-y-[0px]',
                'xs:grid',
                'xs:grid-cols-2',
                'sm:grid-cols-4',
                'xs:text-center',
                'xs:max-w-[360px]',
                'sm:max-w-full',
                'xs:mx-auto',
                'xs:gap-x-[20px]',
                'lg:gap-x-[35px]',
                'xs:gap-y-[40px]',
                'lg:gap-y-[0px]'
              )}
            >
              {list.map(({ icon, label }, i:number) => (
                <li
                  key={label}
                  className={classNames(
                    'flex',
                    'xs:flex-col',
                    'justify-center',
                    'items-center',
                    'space-x-[10px]',
                    'xs:space-x-[0px]'
                  )}
                >
                  <div
                    className={classNames(
                      'xs:h-[100px]',
                      'flex-shrink-0',
                      'justify-center',
                      'items-center',
                      'flex',
                      'relative',
                      'xs:mx-auto',
                      'xs:mb-[-12px]'
                    )}
                  >
                    <SvgIcon
                      className={classNames(
                        'w-[80px]',
                        i === 0 ? 'sm:w-[70px]' : 'sm:w-[100px]'
                      )}
                      name={icon}
                    />
                  </div>
                  <div
                    className={classNames(
                      'lg:text-[16px]',
                      'lg:leading-[20px]'
                    )}
                  >
                    <Trans>{label}</Trans>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
