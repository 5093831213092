import classNames from 'classnames';
import { FC } from 'react';

import { MassMediaSlider, Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';

type IMassMediaBasisData = {
  title: string;
  list: {
    image: string;
    text: string;
    url: string;
  }[];
};

export const MassMediaBasis: FC = (props) => {
  const { title, list } = useTranslateData<IMassMediaBasisData>(
    'sections.massMedia'
  ) as IMassMediaBasisData;

  return (
    <section {...props}>
      <div
        className={classNames(
          'pt-[81px]',
          'sm:pt-[128px]',
          'lg:pt-[191px]',
          'pb-[105px]',
          'sm:pb-[226px]',
          'lg:pb-[164px]',
          'xl:pb-[180px]'
        )}
      >
        <div className="container">
          <Headline
            size={3}
            className={classNames(
              'mb-[40px]',
              'sm:mb-[60px]',
              'lg:mb-[90px]',
              'text-center'
            )}
          >
            <Trans>{title}</Trans>
          </Headline>
        </div>
        <MassMediaSlider data={list} variant="basis" />
      </div>
    </section>
  );
};
