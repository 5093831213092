import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageProps } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { FC, useMemo } from 'react';

export interface IImageProps extends Omit<GatsbyImageProps, 'image'> {
  src: string;
  className?: string;
  [name: string]: any;
}

export const LogoImage: FC<IImageProps> = ({ src, alt = '', ...props }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            parent {
              ... on File {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const url: string = useMemo(() => {
    const res = allImageSharp.edges.find((el: any) => {
      const publicName = el.node?.parent?.publicURL?.split('/')?.pop();
      return publicName === src;
    });
    return res?.node?.parent?.publicURL;
  }, [allImageSharp, src]);

  return <img {...props} src={url} alt={alt} />;
};
