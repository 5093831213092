import classNames from 'classnames';
import { FC } from 'react';

import { SvgIcon, Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';
import * as styles from './styles.module.css';

type IWhoWeHelpData = {
  id: string;
  title: string;
  list: {
    icon: string;
    label: string;
  }[];
};

export const WhoWeHelp: FC = (props) => {
  const { id, title, list, description } = useTranslateData<IWhoWeHelpData>(
    'sections.whoWeHelp'
  ) as IWhoWeHelpData;

  return (
    <section id={id} {...props}>
      <div
        className={classNames(
          'pt-[30px]',
          'pb-[40px]',
          'sm:pb-[150px]'
        )}
      >
        <div className={classNames('container')}>
          <Headline
            size={2}
            className={classNames(
              'sticky',
              'top-0',
              'left-0',
              'text-center',
              'mb-[41px]',
              'xs:mx-auto',
              'xs:mb-[45px]',
              'sm:mb-[50px]',
              'lg:max-w-[900px]',
              'xl:mx-auto'
            )}
          >
            <Trans>{title}</Trans>
          </Headline>
          <div
            className={classNames(
              'text-center',
              'mb-[24px]',
              'xs:mb-[15px]',
              'sm:mb-[53px]',
              'lg:mb-[50px]',
              'xs:mx-auto',
              'xs:max-w-[380px]',
              'lg:max-w-[900px]',
              'sm:max-w-full'
            )}
          >
            <Trans>{description}</Trans>
          </div>
        </div>
        <div className={classNames('xs:container')}>
          <div
            className={classNames(
              styles.container,
              'table',
              'sm:block',
              'w-[100%]',
              'sm:mx-auto',
              'lg:max-w-[80%]',
              'lg:max-w-full'
            )}
          >
            <ul
              className={classNames(
                'gap-y-[15px]',
                'sm:text-[14px]',
                'sm:leading-[18px]',
                'lg:text-[20px]',
                'lg:leading-[25px]',
                'flex',
                'flex-wrap',
                'justify-evenly'
              )}
            >
              {list?.map(({ icon, label }) => (
                <li
                  key={label}
                  className={classNames(
                    styles.item,
                    'fles',
                    'flex-col',
                    'items-center',
                    'justify-center',
                    'space-x-[8px]',
                    'sm:space-x-[0px]',
                    'sm:space-y-[8px]',
                    'lg:space-y-[12px]',
                    'min-h-[40px]',
                    'sm:bg-orange-35',
                    'sm:rounded-[6px]',
                    'text-center',
                    'sm:min-h-[164px]',
                    'sm:p-[15px]',
                    'w-[100%]',
                    'sm:w-[22%]',
                    'md:w-[16%]',
                    'lg:min-h-[239px]',
                    'lg:transform'
                  )}
                >
                  <div
                    className={classNames(
                      'text-graphite-90',
                      'relative',
                      'top-[-1px]'
                    )}
                  >
                    <SvgIcon
                      name={icon}
                      className={classNames(
                        'lg:w-[60px]',
                        'lg:h-[60px]',
                        'w-[40px]',
                        'h-[40px]',
                        'mx-auto'
                      )}
                    />
                  </div>
                  <div>
                    <Trans>{label}</Trans>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
