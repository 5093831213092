import classNames from 'classnames';
import { FC } from 'react';

import { MassMediaSlider, Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';

type IMassMediaData = {
  title: string;
  list: {
    image: string;
    text: string;
    url: string;
  }[];
};

export const MassMedia: FC = (props) => {
  const { title, list } = useTranslateData<IMassMediaData>(
    'sections.massMedia'
  ) as IMassMediaData;

  return (
    <section {...props}>
      <div
        className={classNames(
          'pt-[8px]',
          'xs:pt-[50px]',
          'sm:pt-[65px]',
          'lg:pt-[40px]',
          'pb-[60px]',
          'xs:pb-[126px]',
          'sm:pb-[180px]'
        )}
      >
        <div className="container">
          <Headline
            size={3}
            className={classNames(
              'mb-[40px]',
              'xs:mb-[50px]',
              'text-center'
            )}
          >
            <Trans>{title}</Trans>
          </Headline>
        </div>
        <MassMediaSlider data={list} />
      </div>
    </section>
  );
};
