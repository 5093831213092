import classNames from 'classnames';
import { FC } from 'react';

import { Image, SvgIcon, Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Link } from '../../ui';

type IPresentationData = {
  image: string;
  description: string;
  linkText: string;
  url: string;
};

export const Presentation: FC = (props) => {
  const { image, description, linkText, url } =
    useTranslateData<IPresentationData>(
      'sections.presentation'
    ) as IPresentationData;

  return (
    <section {...props}>
      <div
        className={classNames(
          'container',
          'pt-[67px]',
          'xs:pt-[66px]',
          'sm:pt-[104px]',
          'lg:pt-[80px]',
          'pb-[72px]',
          'xs:pb-[80px]',
          'sm:pb-[101px]',
          'lg:pb-[140px]',
          'sm:max-w-[716px]',
          'lg:max-w-[1080px]',
          'xl:max-w-[1280px]'
        )}
      >
        <div
          className={classNames(
            'sm:flex',
            'sm:justify-between',
            'xl:relative',
            'xl:left-[157px]'
          )}
        >
          <div
            className={classNames(
              'max-w-[274px]',
              'sm:max-w-[334px]',
              'lg:max-w-[427px]',
              'mx-auto',
              'sm:mx-0',
              'relative',
              'left-[2px]',
              'xs:left-[-4px]',
              'sm:left-[9px]',
              'lg:left-[-15px]'
            )}
          >
            <Image src={image} alt="презентация продукта" />
          </div>
          <div
            className={classNames(
              'mt-[24px]',
              'sm:mt-[0px]',
              'max-w-[252px]',
              'sm:max-w-[316px]',
              'lg:max-w-[374px]',
              'mx-auto',
              'sm:mx-0',
              'relative',
              'sm:self-end',
              'sm:pb-[22px]',
              'lg:pb-[33px]',
              'sm:ml-[58px]',
              'tracking-[-0.2px]',
              'xs:relative',
              'xs:left-[-6px]'
            )}
          >
            <div
              className={classNames(
                'absolute',
                'right-full',
                'bottom-full',
                'transform',
                'rotate-[23deg]',
                'translate-x-[4px]',
                'sm:translate-x-[12px]',
                'sm:translate-y-[-6px]',
                'lg:translate-x-[-2px]',
                'lg:translate-y-[-30px]',
                'sm:rotate-[-82deg]',
                'lg:rotate-[-84deg]',
                'scale-[1.2]',
                'sm:scale-x-[-1]',
                'lg:scale-x-[-2]',
                'lg:scale-y-[2]',
                'text-graphite-70'
              )}
            >
              <SvgIcon name="rounded_arrow_up" />
            </div>
            <div
              className={classNames(
                'space-y-[8px]',
                'lg:space-y-[14px]',
                'text-[16px]',
                'leading-[18px]',
                'lg:text-[20px]',
                'lg:leading-[25px]',
                'lg:tracking-[-0.3px]'
              )}
            >
              <div className={classNames('text-graphite-70')}>
                <Trans>{description}</Trans>
              </div>
              <div>
                <Link
                  to={url}
                  download
                  className={classNames('text-orange-60')}
                >
                  <Trans>{linkText}</Trans>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
