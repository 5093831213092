import classNames from 'classnames';
import { FC } from 'react';

import { IImageProps, Image } from '../../../components';
import * as styles from './styles.module.css';

interface ILogo extends Partial<IImageProps> {
  showCorners?: boolean;
  contentProps?: {
    className?: string;
    onClick?(): void;
  };
}

export const Logo: FC<ILogo> = ({ contentProps, showCorners, ...props }) => {
  return (
    <div
      className={classNames(
        styles.cornerLeftTop,
        !showCorners && styles.cornerHidden
      )}
    >
      <div
        className={classNames(
          styles.cornerLeftBottom,
          !showCorners && styles.cornerHidden
        )}
      >
        <div
          className={classNames(
            styles.cornerRightTop,
            !showCorners && styles.cornerHidden
          )}
        >
          <div
            className={classNames(
              styles.cornerRightBottom,
              !showCorners && styles.cornerHidden
            )}
          >
            <div {...contentProps}>
              <Image {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
