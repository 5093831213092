import classNames from 'classnames';
import { FC, useCallback, useRef } from 'react';

import { SvgIcon, Trans, Video } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline, Link } from '../../ui';
import * as styles from './styles.module.css';

type IWhatYouReceiveData = {
  id: string;
  title: string;
  list: {
    icon: string;
    label: string;
    text: string;
    linkText?: string;
    url?: string;
  }[];
};

const SPECIFIED_COLOR = '#f9f6f2';

const targetsBlank = ['/service-api'];

export const WhatYouReceive: FC = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { id, title, list } = useTranslateData<IWhatYouReceiveData>(
    'sections.whatYouReceive'
  ) as IWhatYouReceiveData;

  const handleGetVideoBgColor = useCallback((color) => {
    containerRef.current?.style.setProperty('background-color', color);
  }, []);

  return (
    <section id={id} {...props}>
      <div className="bg-orange-35" ref={containerRef}>
        <div
          className={classNames(
            'container',
            'pt-[59px]',
            'xs:pt-[81px]',
            'sm:pt-[117px]',
            'lg:pt-[120px]',
            'pb-[55px]',
            'xs:pb-[74px]',
            'sm:pb-[108px]',
            'lg:pb-[112px]'
          )}
        >
          <Headline
            size={2}
            className={classNames(
              'mb-[22px]',
              'xs:mb-[51px]',
              'sm:mb-[88px]',
              'lg:mb-[200px]',
              'text-center',
              'lg:mx-auto',
              'max-w-[900px]',
              'sm:relative',
              'sm:z-[1]'
            )}
          >
            <Trans>{title}</Trans>
          </Headline>
          <div className={classNames('sm:relative')}>
            <div
              className={classNames(
                'hidden',
                'sm:block',
                'sm:absolute',
                'sm:w-[88%]',
                'md:w-[74%]',
                'lg:w-[808px]',
                'sm:left-[-20%]',
                'md:left-[-10%]',
                'lg:left-1/2',
                'sm:top-[210px]',
                'md:top-[148px]',
                'lg:top-[-214px]',
                'lg:transform',
                'lg:-translate-x-1/2'
              )}
            >
              <Video
                name="cube2"
                autoPlay
                muted
                loop
                playsInline
                onGetVideoBgColor={handleGetVideoBgColor}
                specifiedColor={SPECIFIED_COLOR}
              />
            </div>
            <ul
              className={classNames(
                'text-center',
                'sm:text-left',
                'sm:relative',
                'sm:z-[1]',
                'lg:min-h-[560px]',
                'xl:min-h-[642px]'
              )}
            >
              {list.map(({ icon, label, text, linkText, url }) => (
                <li
                  key={icon}
                  className={classNames(
                    styles.item,
                    'max-w-[320px]',
                    'mt-[48px]',
                    'sm:mt-0',
                    'sm:max-w-[264px]',
                    'mx-auto',
                    'sm:mx-0',
                    'sm:relative',
                    'lg:absolute'
                  )}
                >
                  <div className={classNames('text-orange-60', 'sm:ml-[-4px]')}>
                    <SvgIcon
                      name={icon}
                      className={classNames(
                        'w-[44px]',
                        'sm:w-[50px]',
                        'lg:w-[54px]',
                        'h-[44px]',
                        'sm:h-[50px]',
                        'lg:h-[54px]',
                        'inline-block'
                      )}
                    />
                  </div>
                  <div
                    className={classNames(
                      'mt-[12px]',
                      'sm:mt-[14px]',
                      'lg:mt-[17px]'
                    )}
                  >
                    <div
                      className={classNames(
                        'text-[18px]',
                        'leading-[21px]',
                        'lg:text-[24px]',
                        'lg:leading-[28px]',
                        'font-bold',
                        'tracking-[-0.4px]'
                      )}
                    >
                      <Trans>{label}</Trans>
                    </div>
                    <div
                      className={classNames(
                        'mt-[6px]',
                        'sm:mt-[5px]',
                        'sm:text-[18px]',
                        'sm:leading-[23px]',
                        'lg:text-[20px]',
                        'lg:leading-[25px]'
                      )}
                    >
                      <div>{text}</div>
                      {url && (
                        <div className={classNames('mt-[10px]')}>
                          <Link
                            to={url}
                            className={classNames('text-orange-60')}
                            inNewTab={targetsBlank.includes(url)}
                          >
                            <Trans>{linkText}</Trans>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
