import classNames from 'classnames';
import { FC } from 'react';

import { Trans } from '../../components';
import { LogoImage } from '../../components/LogoImage';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';

type TranslateData = {
  title: string;
  list: {
    image: string;
    alt: string;
    additionalStyle?: string;
    order?: string;
  }[];
};

export const KeyPartners: FC = (props) => {
  const { title, list } = useTranslateData<TranslateData>(
    'sections.keyPartners'
  ) as TranslateData;

  return (
    <section {...props}>
      <div
        className={classNames(
          'bg-graphite-20',
          'pt-[49px]',
          'sm:pt-[80px]',
          'lg:pt-[161px]',
          'pb-[53px]',
          'sm:pb-[83px]',
          'lg:pb-[171px]'
        )}
      >
        <div className={classNames('container')}>
          <Headline
            size={2}
            className={classNames(
              'text-center',
              'mb-[36px]',
              'sm:mb-[54px]',
              'lg:mb-[76px]'
            )}
          >
            <Trans>{title}</Trans>
          </Headline>
          <div
            className={classNames(
              'max-w-[290px]',
              'mx-auto',
              'sm:max-w-[92%]',
              'lg:max-w-[1050px]',
              'flex',
              'flex-wrap',
              'justify-between',
              'sm:justify-center',
              'gap-y-[20px]',
              'gap-x-[25px]',
              'sm:gap-y-[30px]',
              'sm:gap-x-[30px]',
              'lg:gap-y-[45px]',
              'lg:gap-x-[50px]',
              'xl:gap-y-[45px]'
            )}
          >
            {list.map(({ image, additionalStyle = '', alt = '' }) => (
              <div
                key={image}
                className={classNames(
                  'mx-auto',
                  'sm:mx-0',
                  'h-[50px]',
                  'sm:h-[65px]',
                  'lg:h-[80px]',
                  'sm:col-span-2',
                  'lg:flex-grow-1',
                  additionalStyle
                )}
              >
                <LogoImage
                  alt={alt}
                  src={image}
                  className={classNames('h-full')}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
