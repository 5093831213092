import classNames from 'classnames';
import { FC } from 'react';

import { Image, Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline, Link } from '../../ui';

type IAboutData = {
  title: string;
  description: string;
  text: string[];
  list: string[];
  link: {
    label: string;
    url: string;
  };
};

export const About: FC = (props) => {
  const { title, description, text, list, link } = useTranslateData<IAboutData>(
    'sections.about'
  ) as IAboutData;

  return (
    <section {...props}>
      <div
        className={classNames(
          'container',
          'pt-[24px]',
          'sm:pt-[83px]',
          'lg:pt-[138px]',
          'pb-[40px]',
          'sm:pb-[86px]',
          'lg:pb-[90px]'
        )}
      >
        <Headline
          size={2}
          className={classNames(
            'text-center',
            'mb-[9px]',
            'sm:mb-[16px]',
            'lg:mb-[20px]',
            'xl:max-w-[980px]',
            'xl:mx-auto'
          )}
        >
          <div
            className={classNames(
              'sm:text-[45px]',
              'lg:text-[65px]',
              'sm:leading-[50px]',
              'lg:leading-[80px]',
              'sm:tracking-[-1.93px]',
              'lg:tracking-[-2px]'
            )}
          >
            <Trans>{title}</Trans>
          </div>
        </Headline>
        <div
          className={classNames(
            'text-center',
            'mb-[38px]',
            'sm:mb-[84px]',
            'lg:mb-[194px]',
            'sm:max-w-[630px]',
            'sm:mx-auto'
          )}
        >
          <Trans>{description}</Trans>
        </div>
        <div
          className={classNames(
            'bg-graphite-90',
            'rounded-[10px]',
            'sm:rounded-[15px]',
            'px-[18px]',
            'sm:px-[60px]',
            'lg:pl-[129px]',
            'xl:pl-[158px]',
            'pt-[25px]',
            'sm:pt-[60px]',
            'lg:pt-[150px]',
            'pb-[28px]',
            'sm:pb-[65px]',
            'lg:pb-[170px]',
            'text-white',
            'lg:mx-[-55px]',
            'xl:mx-[-5px]',
            'lg:flex',
            'lg:space-x-[84px]',
            'xl:space-x-[124px]'
          )}
        >
          <div
            className={classNames('mb-[33px]', 'sm:mb-[41px]', 'lg:mb-[0px]')}
          >
            <Image
              alt="logo"
              src="logo_basis.svg"
              className={classNames('sm:w-[170px]', 'lg:w-[300px]')}
            />
          </div>
          <div>
            <div
              className={classNames(
                'mb-[22px]',
                'sm:mb-[28px]',
                'lg:mb-[24px]',
                'sm:text-[18px]',
                'lg:text-[20px]',
                'sm:leading-[23px]',
                'lg:leading-[26px]',
                'sm:tracking-[-0.55px]',
                'lg:tracking-[-0.48px]',
                'sm:max-w-[504px]',
                'lg:max-w-[540px]'
              )}
            >
              <div
                className={classNames(
                  'space-y-[10px]',
                  'sm:space-y-[12px]',
                  'lg:space-y-[14px]',
                  'mb-[15px]',
                  'sm:mb-[18px]',
                  'lg:mb-[16px]'
                )}
              >
                {text.map((item) => (
                  <div key={item}>
                    <Trans>{item}</Trans>
                  </div>
                ))}
              </div>
              <ul
                className={classNames(
                  'list-square',
                  'list-outside',
                  'pl-[18px]',
                  'sm:pl-[29px]',
                  'space-y-[14px]',
                  'sm:space-y-[18px]',
                  'lg:space-y-[16px]',
                  'text-green-80'
                )}
              >
                {list.map((item) => (
                  <li key={item}>
                    <span className="text-white">
                      <Trans>{item}</Trans>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={classNames(
                'text-green-80',
                'lg:text-[20px]',
                'lg:leading-[26px]'
              )}
            >
              <Link to={link.url}>
                <Trans>{link.label}</Trans>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
