import classNames from 'classnames';
import { FC, useCallback, useRef, useState } from 'react';

import { /*Image,*/ Slide, Slider, Trans } from '../../components';
import { LogoImage } from '../../components/LogoImage';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';
import { Logo } from './Logo';
import * as styles from './styles.module.css';

type Reviews = {
  name: string;
  position: string;
  description: string;
  image: string;
}[];

type TranslateData = {
  title: string;
  list: { image: string; alt: string; additionalStyle?: string }[];
  reviews: Reviews;
};

export const Partners: FC = (props) => {
  const sliderRef = useRef<{ splide: any }>();
  const [activeSlide, setActiveSlide] = useState(0);
  const { title, list, reviews } = useTranslateData<TranslateData>(
    'sections.partners'
  ) as TranslateData;

  const handleActive = useCallback((_, { index }) => {
    setActiveSlide(index);
  }, []);

  const handleGoTo = useCallback((index) => {
    const instance = sliderRef.current?.splide;
    instance.go(index);
  }, []);

  return (
    <section {...props}>
      <div
        className={classNames(
          'pt-[82px]',
          'xs:pt-[92px]',
          'sm:pt-[95px]',
          'lg:pt-[120px]',
          'pb-[50px]',
          'xs:pb-[57px]',
          'sm:pb-[65px]',
          'lg:pb-[105px]'
        )}
      >
        <div className="container">
          <Headline
            size={2}
            className={classNames(
              'text-center',
              'mb-[35px]',
              'sm:mb-[60px]',
              'lg:mb-[90px]'
            )}
          >
            <Trans>{title}</Trans>
          </Headline>
          <div
            className={classNames(
              'max-w-[290px]',
              'mx-auto',
              'sm:max-w-[92%]',
              'lg:max-w-[1031px]',
              'flex',
              'flex-wrap',
              'justify-between',
              'sm:justify-center',
              'gap-y-[20px]',
              'gap-x-[25px]',
              'sm:gap-y-[30px]',
              'sm:gap-x-[30px]',
              'lg:gap-y-[45px]',
              'lg:gap-x-[50px]',
              'xl:gap-y-[45px]'
            )}
          >
            {list.map(({ image, alt, additionalStyle }) => (
              <div
                key={image}
                className={classNames(
                  'mx-auto',
                  'sm:mx-0',
                  'h-[50px]',
                  'sm:h-[65px]',
                  'lg:h-[80px]',
                  'sm:col-span-2',
                  'lg:flex-grow-1',
                  additionalStyle
                )}
              >
                <LogoImage
                  alt={alt}
                  src={image}
                  className={classNames('h-full')}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={classNames('sm:container')}>
          <div
            className={classNames(
              'mt-[60px]',
              'sm:mt-[92px]',
              'lg:mt-[118px]',
              'sm:bg-orange-35',
              'sm:rounded-[8px]',
              'relative'
            )}
          >
            <div
              className={classNames(
                'hidden',
                'sm:flex',
                'px-[24px]',
                'absolute',
                'z-[1]',
                'space-x-[20px]',
                'top-[58px]',
                'lg:top-[100px]',
                'sm:w-full',
                'sm:justify-center'
              )}
            >
              {reviews.map(({ image }, index) => (
                <div key={image} className={classNames('flex')}>
                  <Logo
                    src={image}
                    showCorners={activeSlide === index}
                    contentProps={{
                      className: classNames(
                        'relative',
                        'z-10',
                        'h-[62px]',
                        'lg:h-[102px]',
                        'w-[146px]',
                        'lg:w-[242px]',
                        'flex',
                        'justify-center',
                        'items-center',
                        'cursor-pointer',
                        'transform',
                        'transition-all',
                        activeSlide === index
                          ? ['opacity-1', 'scale-100']
                          : ['opacity-[.3]', 'hover:opacity-[1]', 'scale-75']
                      ),
                      onClick: () => handleGoTo(index),
                    }}
                    className="max-w-[70%]"
                  />
                </div>
              ))}
            </div>
            <Slider
              ref={sliderRef}
              className={styles.slider}
              options={{
                perPage: 1,
                arrows: false,
                autoWidth: true,
                focus: 'center',
                trimSpace: false,
                drag: true,
                gap: 0,
              }}
              onActive={handleActive}
            >
              {reviews.map(({ name, image, position, description }) => (
                <Slide key={name} className="flex">
                  <div className={classNames(styles.slide, 'flex')}>
                    <div
                      className={classNames(
                        'bg-orange-35',
                        'sm:bg-opacity-0',
                        'rounded-[8px]',
                        'sm:rounded-none',
                        'pt-[40px]',
                        'xs:pt-[45px]',
                        'sm:pt-[158px]',
                        'lg:pt-[260px]',
                        'pb-[40px]',
                        'xs:pb-[50px]',
                        'sm:pb-[70px]',
                        'lg:pb-[110px]',
                        'px-[18px]',
                        'xs:px-[34px]',
                        'sm:px-[50px]',
                        'lg:px-[190px]',
                        'flex'
                      )}
                    >
                      <div>
                        <div
                          className={classNames(
                            'flex',
                            'justify-center',
                            'mb-[30px]',
                            'sm:hidden'
                          )}
                        >
                          <Logo
                            src={image}
                            contentProps={{
                              className: classNames(
                                'h-[58px]',
                                'w-[136px]',
                                'flex',
                                'justify-center',
                                'items-center'
                              ),
                            }}
                            showCorners
                          />
                        </div>
                        <div className={classNames('sm:text-center')}>
                          <div
                            className={classNames(
                              'text-center',
                              'font-bold',
                              'mb-[2px]',
                              'sm:mb-[0px]',
                              'sm:text-[16px]',
                              'sm:leading-[20px]',
                              'lg:text-[24px]',
                              'lg:leading-[32px]',
                              'sm:inline'
                            )}
                          >
                            <Trans>{name}</Trans>
                            <span className={classNames('hidden', 'sm:inline')}>
                              ,{' '}
                            </span>
                          </div>
                          <div
                            className={classNames(
                              'text-[14px]',
                              'leading-[18px]',
                              'text-center',
                              'sm:text-[16px]',
                              'sm:leading-[20px]',
                              'lg:text-[24px]',
                              'lg:leading-[32px]',
                              'sm:inline'
                            )}
                          >
                            <Trans>{position}</Trans>
                          </div>
                        </div>
                        <div
                          className={classNames(
                            'mt-[18px]',
                            'xs:mt-[20px]',
                            'sm:mt-[14px]',
                            'lg:mt-[25px]',
                            'text-center',
                            'sm:text-[24px]',
                            'sm:leading-[32px]',
                            'lg:text-[35px]',
                            'lg:leading-[41px]'
                          )}
                        >
                          «<Trans>{description}</Trans>»
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};
