import classNames from 'classnames';
import { FC, useMemo } from 'react';

import { Accordion, Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';

type IFaqDataListItem = {
  title: string;
  text: string[];
  list?: string[];
};

type IFaqData = {
  id: string;
  title: string;
  list: IFaqDataListItem[];
};

export const FAQ: FC = (props) => {
  const { id, title, list } = useTranslateData<IFaqData>(
    'sections.faq'
  ) as IFaqData;
  const listByColumn = useMemo(() => {
    return list.reduce<[IFaqDataListItem[], IFaqDataListItem[]]>(
      (acc, item, index, srcArr) => {
        const isSecondCol = Math.ceil((srcArr.length - 1) / 2) > index;

        acc[isSecondCol ? 0 : 1].push(item);

        return acc;
      },
      [[], []]
    );
  }, [list]);

  return (
    <section id={id} {...props}>
      <div
        className={classNames(
          'container',
          'pt-[16px]',
          'sm:pt-[65px]',
          'lg:pt-[45px]',
          'pb-[50px]',
          'xs:pb-[52px]',
          'sm:pb-[64px]',
          'lg:pb-[100px]'
        )}
      >
        <Headline
          size={3}
          className={classNames(
            'text-center',
            'lg:text-left',
            'mb-[39px]',
            'xs:mb-[43px]',
            'sm:mb-[50px]',
            'lg:mb-[38px]',
            'xl:mb-[45px]'
          )}
        >
          <Trans>{title}</Trans>
        </Headline>
        <div
          className={classNames(
            'space-y-[26px]',
            'lg:space-y-[0px]',
            'lg:space-x-[78px]',
            'sm:max-w-[500px]',
            'lg:max-w-[100%]',
            'sm:mx-auto',
            'lg:flex',
            'xs:max-w-[420px]',
            'sm:max-w-[494px]',
            'xs:mx-auto',
            'lg:ml-[-24px]'
          )}
        >
          {listByColumn?.map?.((column, index) => {
            return (
              <div
                key={index}
                className={classNames(
                  'space-y-[28px]',
                  'xs:space-y-[26px]',
                  'sm:space-y-[30px]',
                  'xl:space-y-[28px]',
                  'lg:flex-1'
                )}
              >
                {column?.map(({ title, text, list }) => (
                  <Accordion
                    key={title}
                    title={title}
                    titleClassName={classNames(
                      'items-start',
                      'sm:text-[18px]',
                      'sm:leading-[21px]',
                      'lg:text-[24px]',
                      'lg:leading-[32px]',
                      'lg:font-normal'
                    )}
                    className={classNames('lg:mt-[30px]')}
                    iconClassName={classNames(
                      'lg:h-[32px]',
                      'lg:py-[8px]',
                      'h-[20px]',
                      'py-[2px]'
                    )}
                  >
                    <div
                      className={classNames(
                        'pl-[24px]',
                        'pt-[11px]',
                        // "lg:pt-[11px]",
                        'xs:pb-[12px]',
                        'sm:pb-[20px]',
                        'sm:text-[18px]',
                        'sm:leading-[23px]'
                      )}
                    >
                      <div className={classNames('space-y-[11px]')}>
                        {text?.map?.((item) => (
                          <div key={item}>
                            <Trans>{item}</Trans>
                          </div>
                        ))}
                      </div>
                      {list && (
                        <ul
                          className={classNames(
                            'list-disc',
                            'pl-[20px]',
                            'space-y-[11px]',
                            'sm:space-y-[16px]',
                            'mt-[8px]',
                            'sm:mt-[10px]'
                          )}
                        >
                          {list?.map?.((item) => (
                            <li key={item}>
                              <Trans>{item}</Trans>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </Accordion>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
