import { useAction } from '@reatom/react';
import classNames from 'classnames';
import { FC, useCallback } from 'react';

import { requestPanelActions, SvgIcon, Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Button, Headline } from '../../ui';
import * as styles from './styles.module.css';

export const Tariffs: FC = (props) => {
  const { title, description, list } = useTranslateData('sections.tariffs');
  const { determineValue } = useTranslateData('controls');
  const openRequestPanelAction = useAction(
    requestPanelActions.openRequestPanelAction
  );

  const handleOpenRequestPanel = useCallback(() => {
    openRequestPanelAction();
  }, [openRequestPanelAction]);

  return (
    <section {...props}>
      <div
        className={classNames(
          'container',
          'pt-[24px]',
          'xs:pt-[30px]',
          'sm:pt-[83px]',
          'lg:pt-[118px]',
          'pb-[61px]',
          'xs:pb-[111px]',
          'sm:pb-[123px]',
          'lg:pb-[189px]'
        )}
      >
        <Headline
          size={2}
          className={classNames(
            styles.title,
            'text-center',
            'mb-[9px]',
            'xs:mb-[13px]',
            'sm:mb-[16px]',
            'lg:mb-[20px]'
          )}
        >
          <Trans>{title}</Trans>
        </Headline>
        <div
          className={classNames(
            'text-center',
            'mb-[39px]',
            'xs:mb-[58px]',
            'sm:mb-[72px]',
            'lg:mb-[82px]',
            'xs:max-w-[420px]',
            'sm:max-w-full',
            'lg:max-w-[900px]',
            'xs:mx-auto'
          )}
        >
          <Trans>{description}</Trans>
        </div>
        <div
          className={classNames(
            'sm:flex',
            'space-y-[20px]',
            'sm:space-y-[0px]',
            'sm:space-x-[20px]',
            'lg:space-x-[30px]',
            'xs:max-w-[344px]',
            'sm:max-w-full',
            'lg:max-w-[910px]',
            'xs:mx-auto'
          )}
        >
          {list.map(({ icon, title, description, features, conditions }) => (
            <div
              key={title}
              className={classNames(
                styles.item,
                'flex',
                'flex-col',
                'justify-between',
                'flex-1',
                'rounded-[10px]',
                'px-[18px]',
                'xs:px-[25px]',
                'lg:px-[40px]',
                'pt-[20px]',
                'xs:pt-[30px]',
                'lg:pt-[40px]',
                'pb-[30px]',
                'xs:pb-[40px]',
                'lg:pb-[46px]'
              )}
            >
              <div className={classNames('mb-[25px]', 'xs:mb-[29px]')}>
                <div
                  className={classNames(
                    'text-orange-60',
                    'mb-[15px]',
                    'xs:mb-[13px]',
                    'lg:mb-[28px]'
                  )}
                >
                  <SvgIcon
                    name={icon}
                    className={classNames(
                      'xs:w-[52px]',
                      'xs:h-[52px]',
                      'lg:h-[74px]',
                      'lg:w-[74px]'
                    )}
                  />
                </div>
                <div
                  className={classNames(
                    'font-bold',
                    'text-[20px]',
                    'xs:text-[24px]',
                    'lg:text-[40px]',
                    'leading-[25px]',
                    'xs:leading-[28px]',
                    'lg:leading-[50px]',
                    'tracking-[-0.4px]',
                    'xs:tracking-[-0.6px]',
                    'lg:tracking-[-1.2px]',
                    'mb-[9px]',
                    'lg:mb-[10px]'
                  )}
                >
                  <Trans>{title}</Trans>
                </div>
                <div
                  className={classNames(
                    'sm:text-[16px]',
                    'lg:text-[18px]',
                    'sm:leading-[20px]',
                    'lg:leading-[23px]',
                    'sm:tracking-[-0.3px]',
                    'lg:tracking-[-0.55px]'
                  )}
                >
                  <div className={classNames('mb-[17px]', 'lg:mb-[25px]')}>
                    <Trans>{description}</Trans>
                  </div>
                  <ul
                    className={classNames(
                      'list-outside',
                      'space-y-[10px]',
                      'lg:space-y-[15px]',
                      'mb-[16px]',
                      'xs:mb-[18px]',
                      'lg:mb-[24px]'
                    )}
                  >
                    {features.map((feature) => (
                      <li
                        key={feature}
                        className={classNames('flex', 'space-x-[8px]')}
                      >
                        <div className={classNames('text-orange-60')}>
                          <SvgIcon
                            name="check"
                            className={classNames('lg:w-[20px]', 'lg:h-[20px]')}
                          />
                        </div>
                        <div>
                          <Trans>{feature}</Trans>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div>
                    <Trans>{conditions}</Trans>
                  </div>
                </div>
              </div>
              <Button
                className={classNames(styles.control)}
                onClick={handleOpenRequestPanel}
                name="openRequestPanelFromTariffs"
              >
                <Trans>{determineValue}</Trans>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
